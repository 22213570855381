import React, { useState, ChangeEvent } from 'react';
import styles from './Form.module.scss';

import EmailGeneratorLayout from './components/EmailGeneratorLayout';
import { SwingSearchFormValues } from './types';
import { useSearchTemplate } from './hooks';

const SwingSearchGenerator = () => {
  const [formState, setFormState] = useState<SwingSearchFormValues>({
    fullName: '',
    fullNameLink: '',
    title: '',
    phone: '',
    email: '',
    photoUrl: '',
  });

  const template = useSearchTemplate(formState);

  const updateFormInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const form = (
    <form className={styles.form}>
      <label htmlFor='fullName' className={styles.label}>
        Full name:
      </label>
      <input
        name='fullName'
        placeholder='Full Name'
        className={styles.input}
        value={formState.fullName}
        onChange={updateFormInput}
      />
      <label htmlFor='fullName' className={styles.label}>
        Full name link (Optional):
      </label>
      <input
        name='fullNameLink'
        placeholder='e.g. https://www.linkedin.com/in/yourname/'
        className={styles.input}
        value={formState.fullNameLink}
        onChange={updateFormInput}
      />
      <label htmlFor='position' className={styles.label}>
        Title:
      </label>
      <input
        name='title'
        placeholder='Title'
        className={styles.input}
        value={formState.title}
        onChange={updateFormInput}
      />
      <label htmlFor='phone' className={styles.label}>
        Phone:
      </label>
      <input
        name='phone'
        placeholder='Phone number'
        className={styles.input}
        value={formState.phone}
        onChange={updateFormInput}
      />
      <label htmlFor='photoUrl' className={styles.label}>
        Photo url:
      </label>
      <input
        name='photoUrl'
        placeholder='e.g. https://yourcomapny.com/photo.jpg'
        className={styles.input}
        value={formState.photoUrl}
        onChange={updateFormInput}
      />
      <label htmlFor='email' className={styles.label}>
        Email (Optional):
      </label>
      <input
        name='email'
        placeholder='Email'
        className={styles.input}
        value={formState.email}
        onChange={updateFormInput}
      />
    </form>
  );

  return <EmailGeneratorLayout template={template} form={form} />;
};

export default SwingSearchGenerator;
