import React from 'react';
import { useClipboard } from './useClipboard';
import styles from './Layout.module.scss';
import { useAuthScreen } from './useAuthScreen';

interface Props {
  template: string;
  form: React.ReactNode;
}

const EmailGeneratorLayout: React.FC<Props> = ({ template, form }) => {
  const { authenticated, authScreen } = useAuthScreen();
  const richTextClipboard = useClipboard({
    copiedTimeout: 1200,
    supportRichText: true,
  });
  const clipboard = useClipboard({ copiedTimeout: 1200 });

  if (!authenticated) return authScreen;

  return (
    <div className={styles.wrapper}>
      <p className={styles.heading}>
        Fill all the fields and copy your footer code!
        <br />
        <a
          href='https://signaturehelp.zendesk.com/hc/en-us/sections/115000797609-Email-client-instructions'
          className={styles.link}
          target='_blank'
          rel='noopener noreferrer'
        >
          Confused? See how to install email footer →
        </a>
      </p>

      <div style={{ display: 'flex' }}>
        {form}

        <div className={styles.footerPreview}>
          <div dangerouslySetInnerHTML={{ __html: template }} />
          {richTextClipboard.renderCopyButton(template, styles.button)}
        </div>
      </div>

      <div className={styles.templateContainer}>
        HTML Code:
        <p className={styles.template}>{template}</p>
      </div>

      {clipboard.renderCopyButton(template, styles.button, 'Copy HTML Code')}
    </div>
  );
};

export default EmailGeneratorLayout;
