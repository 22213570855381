import { SwingDevFormValues } from './types';
import { SwingSearchFormValues } from './types';
import { WEBSITE_URL } from '~/constants';

export const useDevTemplate = (formState: SwingDevFormValues) => {
  const data = {
    facebookUrl: 'https://www.facebook.com/swingdev/',
    instagramUrl: 'https://www.instagram.com/swingdev/',
    linkedInUrl: 'https://www.linkedin.com/company/swing-development/',
    name: 'SwingDev',
    websiteHrefUrl: 'https://www.swing.dev/',
    websiteUrlText: 'www.swing.dev',
  };
  const linkStyle = `"color: #FF002B; font-size: 12px; line-height: 20px; text-decoration:none;"`;
  const tableStyle = `"font-family: -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif; color: #000000"`;

  // prettier-ignore
  // eslint-disable-next-line
  return `<table bgcolor='#fff' cellspacing='0' cellpadding='0' border='0' style=${tableStyle}> <tr> <td> <table bgcolor='#fff' cellspacing='0' cellpadding='0' border='0' style=${tableStyle}> <tr> <td> <span style='font-size: 14px;line-height: 19px;font-weight: bold;'> ${formState.fullName || 'Your Name'}</span> </td></tr><tr> <td> <span style='font-size: 14px;line-height: 19px;'> ${formState.position || 'Your Position'}</span> </td></tr><tr> <td> <span style='font-size: 12px;line-height: 19px;'> T: <a href='tel:${formState.phone.replace( /\s/g, '' )}' style=${linkStyle}> ${' '}${formState.phone || "+48 XXX XXX XXX"}</a> </span> </td></tr><tr style='margin-top: 6px; display: block;'> <td> <span style='font-size: 12px;line-height: 20px;'> <span style='font-weight: bold;'>SwingDev</span> <a style=${linkStyle}href='${data.websiteHrefUrl}'> ${data.websiteUrlText}</a> </span> </td></tr><tr> <td> <span> <a style=${linkStyle}href='${data.facebookUrl}'> Facebook </a> | <a style=${linkStyle}href='${data.linkedInUrl}'> Linkedin </a> | <a style=${linkStyle}href='${data.instagramUrl}'> Instagram </a> </span> </td></tr></table> </td></tr></table>`;
};

const normalizeLink = (link: string) => {
  if (!link || link.includes('http://') || link.includes('https://')) {
    return link;
  }

  return 'https://' + link;
};

export const useSearchTemplate = (formState: SwingSearchFormValues) => {
  const logoUrl = `${WEBSITE_URL}/swing-search-logo.png`;
  const photoStyle = `display: block;width: 60px;margin-bottom:6px;`;
  const logoStyle = `display: block;width: 60px;`;
  const tableStyle = `color: #575563; line-height: 0`;
  const textStyle = `font-family: -apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif;font-size: 12px;line-height: 1.5;`;
  const linkStyle = `color: #ffffff;`;

  const nameLink = normalizeLink(formState.fullNameLink);

  let photoUrl = formState.photoUrl;
  if (photoUrl.includes('drive.google.com')) {
    const id = photoUrl
      .replace('https://drive.google.com/open?id=', '')
      .replace('https://drive.google.com/file/d/', '')
      .replace('/view', '')
      .replace('?usp=sharing', '')
      .replace('/', '');
    photoUrl = `https://drive.google.com/uc?export=view&id=${id}`;
  }

  // prettier-ignore
  // eslint-disable-next-line
  return `<table bgcolor="#fff" cellspacing="0" cellpadding="0" border="0" style="${tableStyle}"> <tr> <td style="padding-right: 12px;border-right: 1px solid #e3e4e8;"> ${photoUrl && `<img src="${photoUrl}" style="${photoStyle}"/>`}<img src="${logoUrl}" style="${logoStyle}"/> </td><td style="padding-left: 12px;"> <table bgcolor="#fff" cellspacing="0" cellpadding="0" border="0" style="${tableStyle}"> <tr> <td> <span style="${textStyle}font-weight: 700;"> ${nameLink && `<a style="${linkStyle}" href="${nameLink}">`} <span style="color: #575563;">${formState.fullName || 'Your Name'}</span> ${nameLink && `</a>`} </span> </td></tr><tr> <td> <span style="${textStyle}color: #575563;">${formState.title || 'Your Title'}</span> </td></tr><tr> <td> <span style="${textStyle}font-weight: 700;"> <a style="${linkStyle}" href="https://swingsearch.com/"><span style="color: #D6878A;">SwingSearch</span></a> </span> </td></tr><tr> <td> <span style="${textStyle}"> <a style="${linkStyle}" href="tel:${formState.phone.replace( /\s/g, '', )}"><span style="color: #575563;">${formState.phone || '+1 XXX XXX XXXX'}</span></a> </span> </td></tr>${formState.email && ` <tr> <td style="display: block; margin-top: -2px"> <span style="${textStyle}"> <a style="${linkStyle}" href="mailto:${formState.email}"><span style="color: #575563;">${formState.email}</span></a> </span> </td></tr>`}</table> </td></tr></table>`;
};
