import React, { useCallback, useState } from 'react';

interface Props {
  copiedTimeout: number;
  supportRichText?: boolean;
}

export const useClipboard = (options: Props) => {
  const { copiedTimeout, supportRichText = false } = options;
  const [copied, setCopied] = useState<boolean>(false);

  const copy = useCallback(
    (value: string) => {
      const clipboardDataType = supportRichText ? 'text/html' : 'text/plain';
      const handleCopy = (e: ClipboardEvent) => {
        // https://www.w3.org/TR/clipboard-apis/#override-copy
        e.clipboardData?.setData(clipboardDataType, value);
        e.preventDefault();
      };
      document.addEventListener('copy', handleCopy);
      document.execCommand('copy');
      document.removeEventListener('copy', handleCopy);

      setCopied(true);
      setTimeout(() => setCopied(false), copiedTimeout);
    },
    [copiedTimeout, supportRichText],
  );

  const renderCopyButton = useCallback(
    (value: string, className?: string, buttonText?: string) => (
      <button
        type='button'
        className={className}
        onClick={() => copy(value)}
        disabled={copied}
      >
        {copied ? 'Copied!' : buttonText || 'Copy'}
      </button>
    ),
    [copied, copy],
  );

  return {
    copy,
    copied,
    renderCopyButton,
  };
};
