import React, { useEffect, useMemo, useState } from 'react';
import styles from './AuthScreen.module.scss';

export const useAuthScreen = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [magicWord, setMagicWord] = useState<string>('');

  useEffect(
    () =>
      setAuthenticated(
        magicWord === 'b' + 'e' + '.' + 'c' + 'u' + 'r' + 'i' + 'o' + 'u' + 's',
      ),
    [magicWord],
  );

  const authScreen = useMemo(
    () => (
      <div className={styles.wrapper}>
        <p className={styles.header}>
          <span role='img' aria-label='magic'>
            ✨
          </span>{' '}
          ENTER <span>MAGIC</span> WORD{' '}
          <span role='img' aria-label='magic'>
            ✨
          </span>
        </p>
        <input
          className={styles.input}
          value={magicWord}
          placeholder='Enter ultra-secret magic word to see this page'
          onChange={(e) => setMagicWord(e.target.value)}
        />
      </div>
    ),
    [magicWord],
  );

  return {
    authScreen,
    authenticated,
  };
};
